// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LNs8dDo-n5rx3uXwlGG52{border:none;appearance:none;-moz-appearance:none;-webkit-appearance:none;background-position:right;background:url(https://staticimg.boutiquefeel.com/site/pc/icon137.png) no-repeat scroll calc(100% - 10px) center transparent;padding-right:26px;padding-left:10px;border-radius:2px;border:1px solid #e6e6e6;box-shadow:none;color:#4b5056;cursor:pointer;height:30px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/components/size-country-selector/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,WAAY,CACZ,eAAgB,CAChB,oBAAqB,CACrB,uBAAwB,CACxB,yBAA0B,CAC1B,4HAA6H,CAC7H,kBAAmB,CACnB,iBAAkB,CAClB,iBAAkB,CAClB,wBAAyB,CACzB,eAAgB,CAChB,aAAc,CACd,cAAe,CACf,WAAY","sourcesContent":[".Select{\n    border: none;\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    background-position: right;\n    background: url(https://staticimg.boutiquefeel.com/site/pc/icon137.png) no-repeat scroll calc(100% - 10px) center transparent;\n    padding-right: 26px;\n    padding-left: 10px;\n    border-radius: 2px;\n    border: 1px solid #e6e6e6;\n    box-shadow: none;\n    color: #4b5056;\n    cursor: pointer;\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Select": "LNs8dDo-n5rx3uXwlGG52"
};
module.exports = ___CSS_LOADER_EXPORT___;
